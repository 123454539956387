@import "../styles/variables";

.selector-button {
  height: 40px;
  padding: 12px 20px;
  align-items: center;

  background-color: transparent;

  &.light {
    color: $color-grey-300;
    &.selected {
      background-color: $color-primary-300;
      color: $color-secondary-300;
    }
  }

  &.dark {
    color: $color-grey-400;
    &.selected {
      background-color: $color-secondary-300;
      color: $color-primary-300;
    }
  }
}
