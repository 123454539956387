.teaser-section {
  display: flex;
  padding: 120px 140px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;

  .teasers-wrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 80px;

    .teaser-wrapper {
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }
  }
}
