@import "../styles/variables";

.article-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  min-width: 300px;

  img {
    width: 100%;
    height: 300px;
  }

  button {
    height: 40px;
    font-size: $font-size-medium;
    margin-top: 8px;
  }

  h5 {
    margin-bottom: auto;
  }
}
