@import "../styles/variables";

.services-wrapper {
  display: flex;
  flex-direction: column;
  padding: 80px 120px;
  gap: 40px;

  .category-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
