@import "../styles/variables";

#parallax-item {
  position: absolute;
  top: 650px;
  height: 500px;
  left: 57%;
  transform: translate(-50%, -50%);

  pointer-events: none;
  user-select: none;
  backdrop-filter: none;
  border: none;
  box-shadow: none;
  z-index: 1;

  transition: none;
}

.variations-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 140px 0px;
}
