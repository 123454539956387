.article-wrapper {
  display: flex;
  padding: 96px 140px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  h2 {
    text-align: center;
  }

  .main-image {
    width: 100%;
    height: auto;

    border-radius: 0px;
  }
}
