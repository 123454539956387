.cta-wrapper {
  display: flex;
  padding: 40px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 72px;
  align-self: stretch;
  text-align: center;

  .card-wrapper {
    padding: 80px 40px;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    border-radius: 32px;
    box-shadow: 0px 20px 50px 0px rgba(2, 5, 0, 0.08);

    .content-wrapper {
      align-items: center;
    }

    h1 {
      max-width: 793px;
    }

    p {
      max-width: 601px;
    }

    button {
      width: 170px;
      height: 52px;
    }
  }
}
